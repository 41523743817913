.summary p {
  margin: 0; }

.summary__top {
  background-color: #ebebeb;
  padding: 16px 0 16px 24px; }

.summary__center {
  padding: 58px;
  box-shadow: 0px 10px 50px -5px rgba(48, 70, 109, 0.15);
  overflow: hidden; }

.summary__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 39px; }
  .summary__item-img {
    width: 62px;
    height: 62px;
    position: relative;
    border: 1px solid #d9d9d9; }
    .summary__item-img img {
      width: 60px;
      height: 60px; }
  .summary__item-badge {
    position: absolute;
    top: -9.5px;
    right: -5.5px; }
  .summary__item-info {
    display: flex;
    margin-left: 15px; }
    .summary__item-info-left {
      max-width: 226px;
      width: 100%; }
    .summary__item-info-right {
      margin-left: 15px; }
  .summary__item-title {
    color: #272c35;
    font-size: 18px;
    line-height: 27px; }
  .summary__item-params {
    margin: 8px 0;
    color: #272c35;
    font-size: 15px;
    line-height: 130%; }
    .summary__item-params p {
      line-height: 130%; }
      .summary__item-params p span {
        margin-left: 4px; }
    .summary__item-params p + p {
      margin-top: 4px; }
  .summary__item-count {
    color: #737373;
    font-size: 12px;
    line-height: 16px; }
  .summary__item-price {
    text-transform: uppercase;
    color: #333;
    line-height: 20.8px; }
    .summary__item-price span {
      margin-right: 4px;
      color: #000;
      font-size: 24px;
      line-height: 36px;
      font-weight: 700; }

.summary__money-lines {
  padding: 39px 0 21px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9; }

.summary__subtotal {
  display: flex;
  justify-content: space-between;
  color: #545454;
  font-size: 15px;
  line-height: 130%; }
  .summary__subtotal-title span {
    margin-right: 4px; }
  .summary__subtotal-price {
    margin-left: 8px;
    text-align: end; }

.summary__subtotal:first-child {
  margin-bottom: 12px; }
  .summary__subtotal:first-child .summary__subtotal-price {
    color: #333333;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700; }
    .summary__subtotal:first-child .summary__subtotal-price span {
      margin-right: 4px; }

.summary__total-cost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  margin: 21px 0 27px; }
  .summary__total-cost-title {
    color: #272c35;
    font-size: 18px;
    line-height: 27px;
    margin-right: 8px; }
  .summary__total-cost span {
    color: #333;
    line-height: 20.8px; }
    .summary__total-cost span span {
      color: #000;
      font-size: 24px;
      line-height: 36px;
      margin-right: 4px; }

.summary__confirm-btn {
  width: 100%;
  padding: 22px;
  line-height: 27px; }
  .summary__confirm-btn:disabled {
    background-color: #f0493e; }

.summary__conditions {
  margin-top: 27px;
  color: #5b626f;
  font-size: 15px;
  line-height: 130%; }
  .summary__conditions-list {
    padding-left: 26px; }
    .summary__conditions-list a:hover {
      text-decoration: underline; }

@media (max-width: 600px) {
  .summary__center {
    padding: 40px; }
  .summary__item-img {
    width: 100px;
    height: 100px; }
    .summary__item-img img {
      width: 98px;
      height: 98px; }
  .summary__item-info {
    flex-direction: column; }
    .summary__item-info-left {
      max-width: 100%; }
    .summary__item-info-right {
      align-self: flex-end; }
  .summary__item-price {
    margin-top: 12px !important;
    text-align: end;
    line-height: 18px; }
    .summary__item-price span {
      font-size: 18px;
      line-height: 22px;
      overflow-wrap: anywhere; }
  .summary__subtotal:first-child .summary__subtotal-price span {
    overflow-wrap: anywhere; }
  .summary__total-cost-title {
    font-size: 14px;
    text-align: start !important; }
  .summary__total-cost span {
    text-align: end;
    line-height: 18px; }
    .summary__total-cost span span {
      font-size: 18px;
      line-height: 22px;
      overflow-wrap: anywhere; } }

@media (max-width: 450px) {
  .summary__center {
    padding: 25px; }
  .summary__item-img {
    width: 72px;
    height: 72px; }
    .summary__item-img img {
      width: 70px;
      height: 70px; }
  .summary__item-title {
    font-size: 16px; }
  .summary__item-params {
    font-size: 14px; }
  .summary__confirm-btn {
    padding: 15px;
    font-size: 16px;
    line-height: 20px; } }
