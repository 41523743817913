@charset "UTF-8";
.top_slider {
  display: flex;
  position: relative;
  height: 90vh; }
  @media (max-height: 900px) {
    .top_slider {
      height: 100vh; } }
  .top_slider_container {
    width: 80%;
    display: flex; }
  .top_slider_wrapper {
    padding-right: 50px; }
    @media (max-width: 1000px) {
      .top_slider_wrapper {
        display: none; } }
  .top_slider__slide {
    width: 100%;
    display: flex; }
  .top_slider-left {
    width: 55%;
    background-blend-mode: lighten;
    text-align: end; }
  .top_slider-right {
    width: 45%;
    background-blend-mode: lighten; }
  .top_slider_category {
    padding-top: 80px;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: auto; }
    .top_slider_category a {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color: #ffffff;
      margin-bottom: 50px;
      transition: all 0.2; }
      .top_slider_category a:hover {
        font-size: 22px; }
  .top_slider__text {
    padding-left: 60px;
    padding-top: 85px; }
    .top_slider__text-1 {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 1px;
      color: #ffffff; }
    .top_slider__text-2 {
      font-style: normal;
      font-weight: 700;
      font-size: 150px;
      line-height: 150px;
      letter-spacing: 1px;
      color: #8c422b;
      text-shadow: 0px 1px 0 white, 0px -1px 0 white, 1px 0px 0 white, -1px 0px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px 1px 0 white, -1px -1px 0 white;
      -webkit-text-shadow: 0px 1px 0 white, 0px -1px 0 white, 1px 0px 0 white, -1px 0px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px 1px 0 white, -1px -1px 0 white;
      -moz-text-shadow: 0px 1px 0 white, 0px -1px 0 white, 1px 0px 0 white, -1px 0px 0 white, 1px 1px 0 white, 1px -1px 0 white, -1px 1px 0 white, -1px -1px 0 white; }
  .top_slider__title {
    padding: 20vh 0 10vh 0;
    position: relative; }
    .top_slider__title-2 {
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 1px;
      color: #272c35;
      text-transform: uppercase; }
  .top_slider_btn button {
    margin-top: 50px; }

.top_social {
  width: 20%;
  display: flex;
  align-items: center;
  position: relative; }
  .top_social_img {
    height: 100%;
    min-width: 60px; }
    .top_social_img img {
      width: 100%;
      height: 100%; }
  .top_social_links {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 60%; }
    .top_social_links a {
      display: block;
      transform: rotate(-90deg);
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #272c35; }
      .top_social_links a::before {
        content: "•";
        color: #272c35;
        position: relative;
        left: -30px; }

.btn_1 {
  padding: 22px 48px;
  gap: 10px;
  background: #272c35;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  position: relative; }

.btn_2 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  color: #272c35;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative; }

.img_bg {
  position: absolute;
  right: 0;
  width: 100vh;
  height: 100%;
  object-fit: cover; }

.top_info_mob {
  display: none;
  text-align: center; }
  @media (max-width: 1000px) {
    .top_info_mob {
      display: block; } }
  @media (max-width: 500px) {
    .top_info_mob .top_slider__title-2 {
      font-size: 20px; }
    .top_info_mob .top_slider_btn {
      display: flex; }
      .top_info_mob .top_slider_btn button {
        width: 50%; }
        .top_info_mob .top_slider_btn button.hidden {
          display: none; } }
  .top_info_mob button {
    margin: 0; }
  .top_info_mob-top {
    display: flex;
    width: 100%; }
    .top_info_mob-top .mob__title {
      width: 50%;
      padding: 20px;
      text-align: start;
      font-size: 17px; }
    .top_info_mob-top .top_slider__title-2 {
      line-height: 30px; }
    .top_info_mob-top .pagination-mob {
      width: 50%; }
      .top_info_mob-top .pagination-mob .swiper-pagination {
        position: static; }

.slide2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 100px;
  background-repeat: no-repeat; }
  @media (max-height: 800px) {
    .slide2 .top_slider_wrapper {
      display: flex;
      align-items: center;
      padding: 50px; }
      .slide2 .top_slider_wrapper .details_btn {
        margin-left: 50px; } }
  @media (max-width: 1000px) {
    .slide2 .top_slider_wrapper {
      display: none; } }
  @media (max-width: 500px) {
    .slide2 {
      background-size: contain; } }
  .slide2_line-1 {
    text-align: end;
    margin-right: 50px;
    z-index: 1; }
  .slide2_line-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%; }
    .slide2_line-2 img {
      max-height: 100%;
      object-fit: contain; }
    .slide2_line-2-1 {
      width: 40%; }
      @media (min-width: 1000px) {
        .slide2_line-2-1 {
          position: relative;
          left: 50px; } }
    .slide2_line-2-2 {
      width: 25%; }
      @media (min-width: 1000px) {
        .slide2_line-2-2 {
          position: relative;
          bottom: 50px; } }
    .slide2_line-2-3 {
      width: 35%; }
      @media (min-width: 1000px) {
        .slide2_line-2-3 {
          position: relative;
          right: 50px; } }
  .slide2_line-3 {
    display: flex;
    align-items: center;
    height: 25%; }
    .slide2_line-3__column {
      margin-left: 100px; }
      .slide2_line-3__column button {
        margin-top: 15px; }
    .slide2_line-3__title {
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 1px;
      color: #ffffff; }

.slide3 .top_slider-left,
.slide3 .top_slider-right {
  width: 50%; }

.slide3 .img_block {
  display: flex; }
  @media (min-width: 1000px) {
    .slide3 .img_block {
      padding-top: 75px; } }

.frame {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 25%; }
  .frame img {
    height: fit-content; }

@media (max-height: 800px) {
  .frame {
    height: 100px; }
    .frame img {
      height: inherit; }
  .top_slider_category {
    padding-top: 40px; }
    .top_slider_category a {
      margin-bottom: 30px; }
  .top_social_links a {
    font-size: 20px; }
  .top_slider__title {
    padding: 15vh 0 10vh 0; } }

@media (max-height: 700px) and (min-width: 1000px) {
  .top_slider_btn button {
    margin-top: 20px; }
  .top_slider__title {
    padding: 15vh 0 5vh 0; }
  .frame {
    height: 80px; }
  .slide2_line-2-1 {
    width: 30%; }
  .slide2_line-2-2 {
    width: 20%; }
  .slide2_line-2-3 {
    width: 25%; }
  .slide2_line-3__title {
    font-size: 30px; }
  .top_slider__text-2 {
    font-size: 100px;
    line-height: 100px; }
  .top_slider_category a {
    margin-bottom: 20px; } }

@media (max-width: 1300px) {
  .top_slider__title img {
    width: 70%; } }

@media (max-width: 1200px) {
  .top_slider__title-2 {
    font-size: 30px; } }

@media (max-width: 1000px) {
  .frame {
    height: 80px; }
    .frame img {
      height: inherit; }
  .img_block {
    padding-top: 0; }
  .slide2 {
    padding: 20px; }
  .top_slider__text {
    padding-top: 0;
    padding-left: 15px; }
  .top_slider_category {
    display: none; }
  .top {
    padding-top: 75px; }
    .top_slider {
      height: 50vh; }
      .top_slider_container {
        width: 100%; }
        .top_slider_container-left, .top_slider_container-right {
          width: 50%; }
  .top_social {
    display: none; } }

@media (max-width: 500px) {
  .frame {
    height: 50px; }
  .img_block img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .top_slider__text {
    position: absolute;
    top: calc(50% - 100px); }
    .top_slider__text-1 {
      font-size: 20px; }
    .top_slider__text-2 {
      font-size: 65px;
      line-height: 50px; } }
