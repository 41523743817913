.about {
  background-color: linear-gradient(180deg, #1A1717 -17.9%, #371010 181.32%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  margin-top: 70px; }
  @media (max-width: 1000px) {
    .about {
      flex-direction: column;
      background-size: 100% 20%;
      background-position: bottom;
      margin-top: 0; }
      .about .about__title,
      .about .about__img {
        width: 100%; } }
  .about__title {
    margin-top: 100px;
    width: 50%; }
    @media (max-width: 500px) {
      .about__title {
        margin-top: 50px; } }
    .about__title-1 {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 130%;
      color: #FFFFFF; }
      @media (max-width: 1000px) {
        .about__title-1 {
          font-size: 15px;
          color: #272C35; } }
    .about__title-2 {
      font-family: 'DIN Pro Cond';
      font-style: normal;
      font-weight: 900;
      font-size: 150px;
      line-height: 189px;
      text-transform: uppercase;
      white-space: nowrap;
      text-shadow: 0px 1px 0 #adadad, 0px -1px 0 #adadad, 1px 0px 0 #adadad, -1px 0px 0 #adadad, 1px 1px 0 #adadad, 1px -1px 0 #adadad, -1px 1px 0 #adadad, -1px -1px 0 #adadad;
      -webkit-text-shadow: 0px 1px 0 #adadad, 0px -1px 0 #adadad, 1px 0px 0 #adadad, -1px 0px 0 #adadad, 1px 1px 0 #adadad, 1px -1px 0 #adadad, -1px 1px 0 #adadad, -1px -1px 0 #adadad;
      -moz-text-shadow: 0px 1px 0 #adadad, 0px -1px 0 #adadad, 1px 0px 0 #adadad, -1px 0px 0 #adadad, 1px 1px 0 #adadad, 1px -1px 0 #adadad, -1px 1px 0 #adadad, -1px -1px 0 #adadad;
      color: #2A1414;
      margin-bottom: -30px; }
      @media (max-width: 1600px) {
        .about__title-2 {
          font-size: 100px;
          margin-bottom: -50px; } }
      @media (max-width: 1000px) {
        .about__title-2 {
          margin-bottom: 0px; } }
      @media (max-width: 600px) {
        .about__title-2 {
          font-size: 50px; } }
  .about__img {
    width: 50%;
    display: flex;
    justify-content: space-around; }
    @media (max-width: 1000px) {
      .about__img {
        padding-bottom: 30px; } }
    .about__img a {
      display: block; }
      .about__img a img {
        max-width: 292px;
        max-height: 412px;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 5px rosybrown; }
