.scope__title {
  max-width: 750px;
  text-align: center;
  margin: 0 auto;
  padding: 100px 0; }
  @media (max-width: 1000px) {
    .scope__title {
      padding: 40px 0; } }
  .scope__title-1 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 53px;
    text-transform: uppercase;
    color: #d9d9d9;
    margin-bottom: 30px; }
    @media (max-width: 500px) {
      .scope__title-1 {
        font-size: 28px;
        line-height: 36px; } }
  .scope__title-2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffffff; }

.uk-child-width-1-2\@s > * {
  width: 100%; }

.column {
  width: 50%; }
  .column__title {
    font-style: normal;
    text-transform: uppercase;
    color: #d9d9d9; }
    .column__title-1 {
      font-weight: 900;
      font-size: 80px;
      line-height: 101px; }
      @media (max-width: 1300px) {
        .column__title-1 {
          font-size: 60px; } }
      @media (max-width: 1000px) {
        .column__title-1 {
          font-size: 40px;
          line-height: 56px; } }
    .column__title-2 {
      font-weight: 400;
      font-size: 42px;
      line-height: 52px; }
      @media (max-width: 1000px) {
        .column__title-2 {
          font-size: 22px;
          line-height: 29px; } }
      .column__title-2-container {
        display: flex;
        flex-wrap: wrap; }
  .column__img {
    width: 100%;
    text-align: end;
    display: flex;
    align-items: flex-end; }
    @media (max-width: 1500px) {
      .column__img {
        flex-direction: column;
        align-items: flex-start;
        max-width: 300px; }
        .column__img img {
          margin-left: 10px; } }
    .column__img a {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 70px;
      width: 170px;
      min-width: 170px; }
      @media (max-width: 1300px) {
        .column__img a {
          margin: 30px 20px; } }
    .column__img img {
      max-width: 558px;
      max-height: 558px;
      width: 100%;
      height: 100%;
      object-fit: contain; }
      @media (max-width: 1400px) {
        .column__img img {
          max-width: 458px; } }
      @media (max-width: 500px) {
        .column__img img {
          width: 60%; } }

.uk-tab-left > * > a {
  border-right: 1px solid #4d1815;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
  color: #828282;
  padding: 25px 50px 25px 0; }

.uk-tab > .uk-active > a {
  border-right: 3px solid #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #ffffff; }
  @media (max-width: 1000px) {
    .uk-tab > .uk-active > a {
      font-size: 20px; } }
  @media (max-width: 400px) {
    .uk-tab > .uk-active > a {
      padding: 5px 0; } }

@media (max-width: 1000px) {
  .scope_columns {
    flex-direction: column; }
  .column {
    width: 100%; } }
