.title {
  text-transform: uppercase;
  color: #000;
  font-size: 30px;
  line-height: 45px;
  font-weight: 700; }
  @media (max-width: 1000px) {
    .title {
      font-size: 24px;
      line-height: 30px; } }

.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: opacity 0.1s ease-out, visibility 0.1s ease-out; }
  .overlay.active {
    visibility: visible;
    opacity: 1; }

.quckly_shop,
.sizes-grid {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 6px 19px 40px 40px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05); }
  .quckly_shop .modal_close,
  .sizes-grid .modal_close {
    color: #000; }
