.modal__block {
  transition: all 0.2s;
  padding: 50px 60px;
  width: 100%;
  overflow-y: auto;
  max-height: 100%;
  max-width: 500px;
  position: fixed;
  opacity: 0;
  top: 50%;
  left: -50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  transition: all 500ms; }
  .modal__block.active {
    z-index: 9999;
    left: 50%;
    opacity: 1; }
  .modal__block .validation_error {
    bottom: -20px;
    left: 10px; }

.modal_details_block {
  transition: all 0.2s;
  padding: 0px;
  width: fit-content;
  height: 90vh;
  overflow-y: hidden;
  max-width: none;
  position: fixed;
  top: 50%;
  left: -50%;
  border-radius: 8px;
  opacity: 0;
  transform: translate(-50%, -50%);
  box-shadow: 10px 5px 5px rosybrown; }
  @media (max-width: 1000px) {
    .modal_details_block {
      width: 100%; } }
  @media (max-width: 480px) {
    .modal_details_block .modal_close {
      top: 20px; } }
  .modal_details_block__img {
    width: 100%;
    height: 100%;
    overflow-x: auto; }
    .modal_details_block__img img {
      min-width: 700px;
      width: 100%;
      height: 100%;
      object-fit: contain; }

.modal_close {
  transition: all 0.2s;
  color: #73cac2;
  cursor: pointer;
  display: flex;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 30px;
  right: 20px; }
  .modal_close:hover {
    color: #73cac2; }

.modal_svg {
  margin-right: 8px; }

.modal_title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 40px; }
  .modal_title img {
    margin-right: 20px; }

.modal_form {
  margin-top: 40px; }

.modal_forget_btn {
  display: block;
  width: 100%;
  margin: 30px 0;
  cursor: pointer;
  text-align: center;
  text-decoration-line: underline; }

.modal_btn__block {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; }
  .modal_btn__block .register {
    border: 1px solid; }

.modal__btn-center {
  display: flex;
  justify-content: center; }

.modal_new_user {
  text-transform: uppercase; }

.modal .mailing {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }
  .modal .mailing_choice {
    display: flex; }
    .modal .mailing_choice__item {
      margin-right: 20px; }

.modal_auth-social {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .modal_auth-social__title {
    color: #828282;
    font-size: 14px; }
  .modal_auth-social a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: black; }
    .modal_auth-social a img {
      margin-right: 5px; }

@media (max-width: 480px) {
  .modal_btn__block {
    flex-direction: column; }
    .modal_btn__block .modal_btn {
      width: 100%;
      margin-bottom: 10px; }
    .modal_btn__block .modal_open {
      width: 100%; }
  .modal_close {
    top: 0; }
  .modal_title {
    justify-content: flex-start;
    padding-right: 30px; }
  .modal__block {
    padding: 15px; }
  .modal_form {
    padding-top: 0px;
    margin-top: 10px; } }

.input_section {
  margin-bottom: 30px; }
  .input_section__title {
    color: #333333;
    font-size: 14px;
    margin: 0 0 5px 25px; }
  .input_section input,
  .input_section textarea {
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    padding: 18px 21px;
    border: 1px solid #f0493e;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff; }
    .input_section input::placeholder,
    .input_section textarea::placeholder {
      color: #ffffff; }
