.title {
  text-align: center;
  padding-top: 40px;
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 53px;
  text-transform: uppercase;
  color: #272c35;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  white-space: nowrap; }
  @media (max-width: 500px) {
    .title {
      font-size: 26px; } }

@media (max-width: 1000px) {
  .youtobe iframe {
    height: 400px; } }

.help {
  position: fixed;
  border-radius: 50%;
  top: 80vh;
  right: 5vw;
  z-index: 100;
  width: 100px;
  height: 100px;
  background: linear-gradient(143.7deg, #0096d6 -16.25%, #00b3ff 78.82%);
  box-shadow: 0px 9px 7px -4px rgba(86, 204, 242, 0.5);
  transition: 0.3s; }
  @media (max-height: 700px) {
    .help {
      top: 78vh;
      right: 1vw;
      width: 80px;
      height: 80px; }
      .help .modal_open {
        padding-top: 25px; } }
  .help.active {
    display: none; }
  .help:hover {
    transform: scale(1.2); }
  .help a {
    padding-top: 35px;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff; }
