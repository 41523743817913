.count__block {
  display: flex;
  align-items: center; }

.count_minus, .count_plus {
  cursor: pointer;
  transition: all .2s;
  position: relative;
  border-radius: 8px;
  width: 36px;
  height: 36px; }
  .count_minus:after, .count_plus:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 1px;
    background: #302929; }

.count_plus:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 8px;
  background: #302929; }

.count_input {
  transition: all .2s;
  background: transparent;
  outline: none;
  border: none;
  margin: 0px 10px;
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 4px; }
