.footer {
  background-color: black; }
  .footer a:hover {
    color: #737988; }
  .footer_social {
    display: flex; }
  .footer_main {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1200px) {
      .footer_main {
        flex-wrap: wrap; } }
    @media (max-width: 1000px) {
      .footer_main {
        display: none; } }
  .footer__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px; }
  .footer_mobile {
    display: none; }
    @media (max-width: 1000px) {
      .footer_mobile {
        display: block; } }
  .footer_nav {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px 15px;
    width: 100%; }
    .footer_nav_link {
      text-transform: uppercase;
      display: flex;
      cursor: pointer; }
    .footer_nav__block {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      background: #fff;
      flex-direction: column; }
    .footer_nav_content {
      transition: all 0.2s;
      visibility: hidden;
      opacity: 0;
      max-height: 1px;
      height: auto;
      display: flex;
      flex-direction: column;
      position: relative;
      top: 0px;
      width: fit-content;
      left: 50%;
      transform: translateX(-50%);
      padding: 5px 15px;
      border-radius: 4px;
      z-index: -1;
      box-shadow: unset;
      width: 100%; }
      .footer_nav_content__block {
        position: relative; }
        .footer_nav_content__block::after {
          content: "";
          position: absolute;
          width: 13px;
          right: 14px;
          height: 3px;
          background: #ffffff;
          top: 20px; }
        .footer_nav_content__block::before {
          transition: all 0.2s;
          content: "";
          position: absolute;
          width: 3px;
          right: 19px;
          height: 12px;
          background: #ffffff;
          top: 15px; }
        .footer_nav_content__block.active .footer_nav_content {
          top: 0;
          opacity: 1;
          z-index: 9;
          visibility: visible;
          max-height: 1000px; }
        .footer_nav_content__block.active::before {
          opacity: 0; }
      .footer_nav_content_link {
        display: flex;
        margin: 10px 0; }
  .footer__bottom {
    padding-bottom: 30px; }
  .footer__column {
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .footer__item {
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 20px; }
    @media (max-width: 1400px) {
      .footer__item {
        font-size: 15px; } }
    @media (max-width: 1000px) {
      .footer__item {
        margin-bottom: 0px; } }
    .footer__item span,
    .footer__item a {
      color: white;
      margin-right: 20px; }
      @media (max-width: 1000px) {
        .footer__item span,
        .footer__item a {
          margin-right: 0px; } }
  .footer__tel {
    display: flex;
    flex-direction: column; }
    .footer__tel a {
      color: white; }

.copiright {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #272c35;
  padding: 8px 0; }
  @media (max-width: 1000px) {
    .copiright {
      display: none; } }
  .copiright_sw {
    margin-right: 200px; }
    .copiright_sw span {
      margin-right: 20px; }
  .copiright_text {
    font-size: 14px;
    line-height: 17px;
    text-align: center; }
  .copiright_mob {
    width: 180px;
    font-size: 10px;
    line-height: 12px;
    font-style: normal;
    font-weight: 500;
    color: #ffffff; }

@media (max-width: 1500px) {
  .footer > .container {
    padding: 0; }
  .footer__column {
    padding: 30px; } }

@media (max-width: 1000px) {
  .footer__column {
    padding: 10px; }
    .footer__column a {
      margin-bottom: 10px; } }
