.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: #272c35;
  font-family: "SF Pro Text";
  color: #fff;
  font-size: 12px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: -0.2px;
  border-radius: 100%;
  cursor: default;
  user-select: none; }

.order {
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px 130px 320px; }
  .order a:hover {
    color: inherit; }
  .order__center {
    display: flex;
    justify-content: space-between;
    margin-top: 24px; }
  .order__left-block {
    max-width: 579px;
    width: 100%; }
  .order__title {
    margin-top: 2.5px; }
  .order__divider {
    display: flex;
    justify-content: center;
    padding: 17px 0 22px; }
    .order__divider span {
      margin: 0 14px;
      text-transform: uppercase;
      color: #5b626f;
      font-size: 14px;
      line-height: 16px; }
    .order__divider hr {
      width: 100%;
      background-color: #e9e9e9; }
  .order__summary {
    position: sticky;
    top: 85px;
    left: 0;
    max-width: 553px;
    width: 100%;
    height: 100%;
    margin-left: 45px; }
  .order__copyright {
    margin: 0;
    font-size: 12px;
    line-height: 18px; }
  @media (max-width: 1415px) {
    .order__summary {
      max-width: 450px; } }
  @media (max-width: 1265px) {
    .order__left-block {
      width: auto; }
    .order__title {
      text-align: center; } }
  @media (max-width: 1150px) {
    .order__center {
      flex-direction: column;
      align-items: center;
      justify-content: stretch; }
    .order__left-block {
      max-width: 100%;
      width: 100%; }
    .order__summary {
      max-width: 100%;
      margin: 40px 0 0; }
    .order__copyright {
      margin-top: 17px; } }
  @media (max-width: 1000px) {
    .order {
      padding: 24px 50px 320px; } }
  @media (max-width: 500px) {
    .order {
      padding: 24px 20px 320px; } }
