@charset "UTF-8";
.form input {
  border: none;
  outline: none; }

.form__part {
  padding: 17px 0; }
  .form__part-title {
    color: #272c35;
    font-size: 18px;
    line-height: 130%;
    font-weight: 500;
    letter-spacing: 0.55px; }

.form__inputs-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px; }

.form__field {
  position: relative;
  width: 100%;
  margin-top: 14px;
  border: 1px solid #ebebeb; }
  .form__field label {
    position: absolute; }
  .form__field input {
    width: 100%; }

.form__checkbox {
  display: flex;
  align-items: start;
  margin-top: 24px; }
  .form__checkbox-wrapper {
    display: flex;
    align-items: start; }
  .form__checkbox-label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 12px;
    color: #000;
    font-size: 15px;
    line-height: 130%; }
    .form__checkbox-label-info {
      display: flex;
      flex-direction: column; }
    .form__checkbox-label-description {
      color: #5b626f;
      font-size: 12px;
      line-height: 18px; }
  .form__checkbox input {
    position: relative;
    min-width: 18px;
    min-height: 18px;
    border: 1px solid #ebebeb;
    border-radius: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .form__checkbox input:checked::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      height: 70%;
      border-radius: 100%;
      background-color: #39c46e; }

.form__delivery-select .custom-select {
  width: 100%; }
  .form__delivery-select .custom-select input {
    padding: 23px 12px 8px;
    color: #5b626f;
    font-size: 15px;
    line-height: 130%;
    border: none;
    border-radius: 0; }
  .form__delivery-select .custom-select__head {
    padding: 0;
    border: none; }
  .form__delivery-select .custom-select__body {
    border: 1px solid #ebebeb;
    border-radius: 0; }
  .form__delivery-select .custom-select__list-item {
    border-radius: 0 !important; }

.form__delivery-select label {
  z-index: 1; }

.form__contacts-field .validation_error, .form__delivery-field .validation_error,
.form .dropdown-block.form__field .validation_error {
  left: 11px;
  bottom: 0; }

.form__contacts-field label, .form__delivery-field label,
.form .dropdown-block.form__field label {
  top: 50%;
  transform: translateY(-50%);
  left: 11px;
  font-size: 15px;
  line-height: 130%;
  color: #5b626f;
  transition: all 0.2s; }
  .form__contacts-field label.active, .form__delivery-field label.active,
  .form .dropdown-block.form__field label.active {
    top: -14px;
    transform: translateY(0%);
    font-size: 12px; }

.form__contacts-field {
  padding: 13.5px 11px; }
  .form__contacts-field-tooltip {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    font-weight: 700; }

.form__contacts-checkbox {
  display: flex;
  align-items: center;
  margin-top: 14px; }
  .form__contacts-checkbox label {
    margin-left: 9px; }
  .form__contacts-checkbox input {
    border-radius: 0; }
    .form__contacts-checkbox input:checked::after {
      content: "✓";
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 90%;
      border-radius: 0; }

.form__delivery-field input {
  padding: 13.5px 11px;
  border: none;
  border-radius: 0; }

.form__delivery-checkbox {
  display: block; }
  .form__delivery-checkbox .dropdown-block {
    display: none; }
    .form__delivery-checkbox .dropdown-block.form__field label {
      top: 30px; }
      .form__delivery-checkbox .dropdown-block.form__field label.active {
        top: -15px; }
    .form__delivery-checkbox .dropdown-block.form__field textarea {
      width: 100%;
      min-height: 100px;
      padding: 11px 11px 0;
      border: none;
      outline: none; }
    .form__delivery-checkbox .dropdown-block .form__delivery-select + .form__delivery-select {
      margin-top: 18px; }
  .form__delivery-checkbox.active .dropdown-block {
    display: block; }

.form__delivery-select {
  position: relative;
  display: flex;
  align-items: center; }
  .form__delivery-select label {
    position: absolute;
    align-items: baseline;
    top: 9px;
    left: 12px;
    color: #c4c5c8;
    font-size: 12px;
    line-height: 18px; }
  .form__delivery-select select {
    width: 100%;
    padding: 23px 12px 8px;
    font-size: 15px;
    line-height: 130%;
    color: #5b626f;
    border: none;
    outline: none;
    appearance: none; }
    .form__delivery-select select:focus + .form__delivery-select-caret {
      transform: rotate(180deg); }
  .form__delivery-select option {
    color: #5b626f;
    font-size: 15px;
    line-height: 130%; }
  .form__delivery-select-caret {
    padding: 5px 15px;
    border-left: 1px solid #ebebeb; }

.g-recaptcha-wrapper {
  position: relative;
  margin-bottom: 27px; }
  @media (max-width: 400px) {
    .g-recaptcha-wrapper iframe {
      width: 100%; }
    .g-recaptcha-wrapper .g-recaptcha > div {
      width: 100% !important; } }

.g-recaptcha-error {
  position: absolute;
  bottom: -15px;
  left: 0;
  display: none;
  margin: 2px;
  color: #f00;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400; }
  .g-recaptcha-error.active {
    display: block; }
