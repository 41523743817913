.validation_container {
  position: relative; }

.validation_error {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  font-size: 12px;
  color: red; }
