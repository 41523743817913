#product .product {
  margin-bottom: 50px; }
  #product .product__title {
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 126px;
    text-transform: uppercase;
    text-align: center;
    text-shadow: 0px 1px 0 #adadad, 0px -1px 0 #adadad, 1px 0px 0 #adadad, -1px 0px 0 #adadad, 1px 1px 0 #adadad, 1px -1px 0 #adadad, -1px 1px 0 #adadad, -1px -1px 0 #adadad;
    -webkit-text-shadow: 0px 1px 0 #adadad, 0px -1px 0 #adadad, 1px 0px 0 #adadad, -1px 0px 0 #adadad, 1px 1px 0 #adadad, 1px -1px 0 #adadad, -1px 1px 0 #adadad, -1px -1px 0 #adadad;
    -moz-text-shadow: 0px 1px 0 #adadad, 0px -1px 0 #adadad, 1px 0px 0 #adadad, -1px 0px 0 #adadad, 1px 1px 0 #adadad, 1px -1px 0 #adadad, -1px 1px 0 #adadad, -1px -1px 0 #adadad;
    color: white; }
  #product .product_card-wrapper {
    max-width: 400px;
    transition: all 1.5s;
    height: auto;
    padding: 0 30px 0 0; }
  #product .product_card {
    width: 100%;
    height: auto;
    border: 1px solid lightgrey;
    border-radius: 8px; }
    #product .product_card__top {
      position: relative;
      height: 390px;
      padding: 20px;
      overflow: hidden; }
      #product .product_card__top:hover .product_card__btn {
        display: flex; }
      #product .product_card__top .european_badge {
        position: absolute;
        width: 40px;
        height: 40px;
        z-index: 100;
        top: 40px;
        right: 40px; }
    #product .product_card__bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 390px);
      padding: 20px;
      border-top: 1px solid lightgrey; }
      #product .product_card__bottom-btn {
        width: 100%;
        margin-top: 15px; }
        #product .product_card__bottom-btn .button-buy {
          width: 100%;
          display: inline-block;
          padding: 5px 15px;
          font-size: 20px;
          cursor: pointer;
          text-align: center;
          text-decoration: none;
          outline: none;
          color: #fff;
          background-color: #4caf50;
          border: none;
          border-radius: 8px;
          transition: all 0.1s; }
          #product .product_card__bottom-btn .button-buy:hover {
            background-color: #3e8e41; }
          #product .product_card__bottom-btn .button-buy:active {
            background-color: #3e8e41;
            transform: translateY(4px); }
      #product .product_card__bottom-left {
        width: 100%; }
    #product .product_card__price {
      font-size: 20px;
      font-weight: bold;
      color: black; }
      #product .product_card__price span {
        word-wrap: break-word; }
    #product .product_card__manufacturer {
      color: blueviolet; }
      #product .product_card__manufacturer:hover {
        color: blueviolet; }
    #product .product_card.active .product_card__top:hover .product_card__btn {
      display: none; }
    #product .product_card.active .product_card_description {
      opacity: 1;
      top: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      position: static; }
    #product .product_card.active .description-button-next,
    #product .product_card.active .description-button-prev {
      display: block; }
    #product .product_card__img {
      max-width: 300px;
      height: 300px;
      margin: 0 auto; }
      #product .product_card__img img {
        width: 100%; }
      #product .product_card__img .product-slide img {
        width: 100%;
        height: 100%;
        max-height: 400px;
        object-fit: contain; }
      #product .product_card__img .description-button-next,
      #product .product_card__img .description-button-prev {
        color: #272c35;
        display: none; }
    #product .product_card__title {
      height: 50px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      text-align: start;
      letter-spacing: 0.759431px;
      color: #302929;
      overflow: hidden; }
    #product .product_card__btn {
      display: none;
      flex-direction: column;
      align-items: center;
      z-index: 100; }
      #product .product_card__btn button {
        margin-top: 10px; }
  #product .product_category__item {
    margin-top: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    text-transform: uppercase;
    color: #272c35;
    padding: 20px;
    background: #f4f4f4;
    height: auto;
    transition: 2s all;
    cursor: pointer; }
    @media (max-width: 500px) {
      #product .product_category__item {
        white-space: nowrap;
        font-size: 11px; } }
    #product .product_category__item.active {
      background: linear-gradient(80deg, #272c35 40%, #f4f4f4 40% 100%);
      color: white;
      animation: background 1s; }

@keyframes background {
  0% {
    background-position: -100px 0px; }
  100% {
    background-position: 0px 0px; } }
    #product .product_category__item:hover {
      background: linear-gradient(80deg, #272c35 40%, #f4f4f4 40% 100%);
      color: white;
      animation: background 1s; }

@keyframes background {
  0% {
    background-position: -100px 0px; }
  100% {
    background-position: 0px 0px; } }

@media (max-width: 1000px) {
  #product .product__title {
    font-size: 50px; } }

@media (max-width: 1000px) {
  #product .product__title {
    font-size: 40px;
    line-height: 50px; } }

@media (max-width: 500px) {
  #product .product__title {
    font-size: 35px;
    line-height: 40px; } }
