@charset "UTF-8";
.swiper-pagination-progressbar {
  width: 25%;
  height: 2px;
  background: #dcdcdc; }
  .swiper-pagination-progressbar-fill {
    background-color: #000000; }

.swiper-pagination-current,
.currentZero {
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 99px;
  text-align: center;
  color: #272c35; }

.swiper-pagination-total,
.totalZero {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #bdbdbd; }

.top_slider-pagination {
  bottom: 8%; }
  @media (max-height: 800px) {
    .top_slider-pagination {
      bottom: 0%; } }

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #000000; }

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  width: 56px;
  height: 56px;
  transition: all 0.2s; }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    transform: scale(1.2); }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 48px; }

.swiper-button-next:after {
  content: "→"; }

.swiper-button-prev:after {
  content: "←"; }

.top-slider-btn-prev,
.top-slider-btn-next {
  top: 86%; }
  @media (max-height: 800px) {
    .top-slider-btn-prev,
    .top-slider-btn-next {
      top: 94%; } }
  @media (max-width: 1000px) {
    .top-slider-btn-prev,
    .top-slider-btn-next {
      top: 86%; } }
  .top-slider-btn-prev:after,
  .top-slider-btn-next:after {
    color: #ffffff; }

.top-slider-btn-prev {
  left: 55%; }

.top-slider-btn-next {
  right: 0; }

.product-slider-btn-next:after,
.product-slider-btn-prev:after {
  color: black; }
