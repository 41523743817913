.header-new {
  position: sticky;
  top: 0;
  display: block;
  background-color: #272c35; }
  .header-new a {
    transition: all 0.2s; }
  .header-new__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto; }
  .header-new__logo {
    padding: 7px 36px 5px; }
  .header-new__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 864px;
    width: 100%;
    padding: 0 52px; }
    .header-new__nav a {
      text-transform: uppercase;
      color: #c4c5c8;
      font-size: 18px;
      line-height: 27px;
      font-weight: 700; }
      .header-new__nav a:hover {
        color: #fff; }
    .header-new__nav-left a + a {
      margin-left: 27px; }
    .header-new__nav-right {
      display: flex;
      justify-content: space-between;
      margin-left: 27px; }
    .header-new__nav-contacts a {
      letter-spacing: 1px; }
    .header-new__nav-contacts a + a {
      margin-left: 38px;
      font-weight: 500;
      letter-spacing: 0.55px; }
    .header-new__nav-btns {
      margin-left: 38px; }
      .header-new__nav-btns button {
        padding: 0; }
  .header-new__cart-btn {
    position: relative;
    width: 24px;
    height: 24px; }
  .header-new__cart-mark {
    display: none;
    position: absolute;
    top: 30%;
    left: 70%;
    width: 7px;
    height: 7px;
    background-color: #f0493e;
    border: 1px solid #ffffff;
    border-radius: 100%; }
    .header-new__cart-mark.active {
      display: inline-block; }
  .header-new__download-btn {
    display: flex;
    max-width: 303px;
    width: 100%;
    background-color: #f0493e;
    transition: all 0.2s; }
    .header-new__download-btn:hover {
      filter: brightness(0.9); }
    .header-new__download-btn a {
      width: 100%;
      padding: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      font-size: 18px;
      line-height: 27px;
      font-weight: 700; }
      .header-new__download-btn a img {
        margin-right: 10px; }
  @media (max-width: 1300px) {
    .header-new__logo {
      padding: 0; }
    .header-new__nav {
      padding: 0; }
      .header-new__nav-left a + a {
        margin-left: 16px; }
      .header-new__nav-contacts a + a {
        margin-left: 16px; }
      .header-new__nav-btns {
        margin-right: 20px; }
    .header-new__download-btn {
      max-width: 200px; } }
  @media (max-width: 1000px) {
    .header-new {
      display: flex;
      align-items: flex-start; }
      .header-new__container {
        width: 100%; }
      .header-new__nav {
        justify-content: flex-end; }
        .header-new__nav-left {
          display: none; }
        .header-new__nav-contacts a:first-child {
          display: none; } }
  @media (max-width: 800px) {
    .header-new {
      align-items: stretch; }
      .header-new__download-btn {
        display: none; } }
  @media (max-width: 550px) {
    .header-new__nav-contacts {
      display: none; }
    .header-new__nav-right {
      margin-left: 10px; }
    .header-new__nav-btns {
      margin-left: 0; }
    .header-new__cart-btn {
      width: 30px;
      height: 30px; }
      .header-new__cart-btn img {
        width: 100%; } }
