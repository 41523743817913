.innovation {
  padding-top: 30px;
  background: linear-gradient(white, #e6e6e6); }
  .innovation__title-1 {
    padding-top: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #272c35;
    margin: 0 auto;
    width: 80%; }
  .innovation__columns {
    display: flex; }
  .innovation__column {
    width: 20%;
    margin-top: 50px; }
  .innovation__img {
    width: 60%;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat; }
    @media (max-width: 1000px) {
      .innovation__img {
        display: none; } }
    .innovation__img .img_2 {
      position: absolute;
      right: 10%;
      bottom: 5%; }
  .innovation_item {
    margin-bottom: 30px; }
    .innovation_item__num {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      text-transform: uppercase;
      color: #272c35;
      position: relative;
      margin-bottom: 10px; }
      .innovation_item__num::after {
        content: "";
        position: absolute;
        top: 15px;
        left: 40px;
        width: calc(100% - 40px);
        height: 2px;
        background: #f0493e; }
    .innovation_item__title {
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 38px;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 10px; }
    .innovation_item__description {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #272c35; }
  @media (max-width: 450px) {
    .innovation {
      padding: 0 16px; } }

@media (max-width: 1000px) {
  .innovation__img {
    display: none; }
  .innovation__columns {
    flex-direction: column; }
  .innovation__column {
    width: 100%; } }
