.btn_red {
  background-color: #ce0f18;
  width: 210px;
  color: white;
  height: 62px;
  font-weight: bold;
  cursor: pointer;
  padding: 20px;
  text-align: center; }
  .btn_red:hover {
    box-shadow: 0px 5px 5px rgba(139, 139, 139, 0.29); }

.btn_white {
  background-color: #ffffff;
  width: 210px;
  color: #ce0f18;
  height: 62px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px; }
  .btn_white:hover {
    box-shadow: 0px 5px 5px rgba(139, 139, 139, 0.29); }

.btn_3 {
  padding: 15px;
  gap: 10px;
  background-color: #272c35;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  position: relative; }
  .btn_3:hover {
    background-color: #5b626f; }

.btn_transparent-bordered {
  background-color: transparent;
  text-transform: uppercase;
  color: #272c35;
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  border: 1px solid #272c35; }
  .btn_transparent-bordered:hover {
    background-color: #272c35;
    color: #fff; }

.go-back-btn {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #272c35;
  font-size: 12px;
  line-height: 18px; }
  .go-back-btn::before {
    content: url("/static/source/img/arrow.svg");
    margin-right: 8px; }
