* p {
  margin: 0; }

* button {
  padding: 0; }

.counter {
  display: flex;
  align-items: baseline; }
  .counter__value,
  .counter button {
    color: #302929;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0.759431px; }
  .counter__value {
    width: 51px;
    margin: 0 5px;
    text-align: center;
    font-weight: 700;
    border: none;
    outline: none; }

.cart-overlay.active .cart {
  transform: translateX(0); }

.cart {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  max-width: 408px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.3s ease-out; }
  .cart__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ebebeb;
    padding: 16px 24px; }
  .cart__title {
    text-transform: uppercase;
    color: #000;
    font-size: 30px;
    line-height: 45px;
    font-weight: 700; }
  .cart__close-btn {
    padding: 6px; }
    .cart__close-btn img {
      width: 12px;
      height: 12px; }
  .cart__items {
    flex: 1;
    margin: 0 24px;
    overflow: auto; }
  .cart__item + .cart__item {
    border-top: 1px solid #ebebeb; }
  .cart__item {
    position: relative;
    display: flex;
    padding: 16px 0; }
    .cart__item img {
      width: 105px;
      height: 105px; }
    .cart__item-remove {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ebebeb;
      border-radius: 100%; }
      .cart__item-remove img {
        width: 12px;
        height: 12px; }
    .cart__item-info {
      margin-left: 16px; }
    .cart__item-title {
      text-transform: uppercase;
      color: #000000;
      font-size: 18px;
      line-height: 27px;
      font-weight: 700; }
    .cart__item-params {
      margin-top: 8px; }
      .cart__item-params p {
        color: #272c35;
        font-size: 15px;
        line-height: 130%; }
        .cart__item-params p span {
          margin-left: 4px; }
      .cart__item-params p + p {
        margin-top: 4px; }
    .cart__item-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px; }
    .cart__item-price {
      margin-left: 16px;
      color: #000;
      font-size: 24px;
      line-height: 36px;
      font-weight: 700; }
      .cart__item-price span {
        margin-right: 4px;
        overflow-wrap: anywhere; }
  .cart__bottom {
    padding: 24px;
    border-top: 1px solid #ebebeb; }
  .cart__total-price-top {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .cart__total-price-top span {
      text-transform: uppercase;
      color: #000;
      font-size: 18px;
      line-height: 27px;
      font-weight: 700; }
  .cart__total-price-count {
    margin-left: 12px;
    font-size: 24px;
    line-height: 36px;
    text-align: end; }
    .cart__total-price-count span {
      margin-right: 4px;
      overflow-wrap: anywhere; }
  .cart__total-price-text {
    margin: 8px 0;
    color: #5b626f;
    font-size: 12px;
    line-height: 18px; }
  .cart__place-order-btn {
    width: 100%;
    padding: 22px 48px; }
  @media (max-width: 600px) {
    .cart__item-bottom {
      flex-direction: column;
      align-items: stretch;
      justify-content: center; }
    .cart__item-price {
      margin: 8px 0 0; }
      .cart__item-price span {
        overflow-wrap: anywhere; } }
